exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-almenr-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=D:/web_dev/richardlundquist.se/work/almenr.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-almenr-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-arabisklitteratur-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=D:/web_dev/richardlundquist.se/work/arabisklitteratur.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-arabisklitteratur-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-birger-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=D:/web_dev/richardlundquist.se/work/birger.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-birger-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-cobuurt-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=D:/web_dev/richardlundquist.se/work/cobuurt.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-cobuurt-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-faunafutura-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=D:/web_dev/richardlundquist.se/work/faunafutura.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-faunafutura-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-invaluableresource-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=D:/web_dev/richardlundquist.se/work/invaluableresource.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-invaluableresource-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-restore-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=D:/web_dev/richardlundquist.se/work/restore.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-restore-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-transformcity-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.js?__contentFilePath=D:/web_dev/richardlundquist.se/work/transformcity.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-d-web-dev-richardlundquist-se-work-transformcity-mdx" */)
}

